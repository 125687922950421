@import url(https://fonts.googleapis.com/css2?family=Squada+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.has-squada-font {
  font-family: 'Squada One', cursive;
}

.navbar {
  z-index: 100;
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid black;
}

a {
  color: rgb(226, 224, 224);
}

a:hover {
  color: #0ff;
  text-decoration: none;
}


.App {
  text-align: center;
  overflow-y: hidden;
}

body::-webkit-scrollbar {
  display: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: hidden;
}

.App-link {
  color: #61dafb;
}

.map-container {
margin-top: 60px;
z-index: 1000;

}

.image-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 100px;
  width: 100vw;
  height: 500px;
  background-repeat: no-repeat;
}

.image-container2 {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 500px;
  width: 100vw;
  height: 500px;
  background-repeat: no-repeat;
}

.image-container3 {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 1000px;
  width: 100vw;
  height: 500px;
  background-repeat: no-repeat;
}

.route-container {
  position: absolute;
  top: 50px;
  bottom: 300px;
  background-color: black;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  color: white;
  padding-top: 50px;

}


.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: white;
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 50%;

}


.surf-button {
  padding:10px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  color: white;
  padding: 20px;
  z-index: 3000;
}


